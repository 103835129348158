import React from "react";
import './homehead.css'

interface Props {
    
}
 
interface State {
    
}
 
class HomeHead  extends React.Component<Props, State> {
    state = { data: ['首页', '介绍', '资质'] }
    render() { 
        return ( 
            < >
                <div className="homeHead">
                    <img src="https://www.yunshixing.com/pc/logo.png" alt="" />
                    <div className="nav">
                    {
                        this.state.data.map((item,index)=>{
                                return <div key={index}>{item}</div>
                        })
                    } 
                    </div>
                </div>
            </>
         );
    }
}
 
export default  HomeHead;