import React from "react";
import './homefooter.css'


interface Props {
}
 
interface State {
}
 
class HomeFooter  extends React.Component<Props, State> {
    state = { 
        data: ''
    }

    
    render() { 
        return ( 
            <div className="homeFooter">
               <div className="link">
                   <div className="wx item"> 
                        <img src="https://www.yunshixing.com/pc/wx.png" alt="" /> 微信号
                        <div >
                            <img src="https://www.yunshixing.com/pc/wxh.jpeg" alt="" />
                        </div>
                   </div>
                   <div className="gz item"> 
                        <img src="https://www.yunshixing.com/pc/gz.png" alt="" /> 公众号
                        <div>
                            <img src="https://www.yunshixing.com/pc/sxgzh.jpg" alt="" />
                        </div>
                   </div>
                   <div className="lx item">
                        <img src="https://www.yunshixing.com/pc/lx.png" alt="" /> 联系我们
                        <div>
                            0393-3899289
                        </div>
                    </div>
               </div>

               <div className="network">网站备/案许可证: <a href="http://beian.miit.gov.cn" target="_blank">豫ICP备 2022010927号</a></div>
               <div className="company">濮阳时行网络科技有限公司</div>
            </div>
        );
    }
}
 
export default HomeFooter;