import React from 'react';
import './App.css';
import HomeView from './HomeView';

function App() {
  return (
    <div className="App">
      <HomeView title="{知己识君}"/>
    </div>
  );
}

export default App;
