import React from "react";
import './homeview.css'
import HomeHead from './HomeHead';
import HomeFooter from './HomeFooter';

interface Props {
    title: String
}
 
interface State {
    data: Object
}
 
class HomeView  extends React.Component<Props, State> {
    state = { 
        data: '',
        videoUrl: '@/static/home.mp4'
    }

    
    render() { 
        return ( 
            <div className="container">
               {/* <video  src="https://www.yunshixing.com/pc/home.mp4" autoPlay></video> */}
               <HomeHead />
               <img className="poster" src="https://zj-shijun.oss-cn-beijing.aliyuncs.com/zhiji/poster_bg.png" alt="" />
               <HomeFooter />
            </div>
        );
    }
}
 
export default HomeView;